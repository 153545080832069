* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

main {
  width: 100%;
  padding: 0 2em 2.5em 2em;
}

.post__list {
  width: 100%;
  padding: 20px;
}

.wrapper {
  display: flex;
}

.menu {
  display: fixed;
  left: 0;
  width: 200px;
  height: 100vh;
  border-right: 1px solid black;
}

.ListItemButton.Mui-selected {
  color: black;
  background-color: rgba(0, 0, 0, 0) !important;
}

.ListItemText {
  color: rgba(0, 0, 0, 0.6);
}

.ListItemButton.Mui-selected::before {
  border-bottom: 0px;
  border-left: 3px solid rgb(6, 125, 247);
  inset: 0.5rem auto 0.5rem 0px;
  content: "";
  position: absolute;
}

.ListItemButton.Mui-selected > .ListItemIcon,
.ListItemButton.Mui-selected > .ListItemText {
  color: rgba(0, 0, 0, 0.87);
}

.orionLogo {
  width: 60px;
  cursor: pointer;
}

.orionLogo:hover {
  opacity: 0.7;
}
