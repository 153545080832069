.icon {
    color: red;
}

.listItem {
    color: black;
}

.listItem.Mui-focused {
    color: red;
}

.logoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0 10px 0;
}

.logo {
    width: 60px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
}
