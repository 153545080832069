.wrapper {
    display: flex;
    align-items: center;
}

.indicator {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-right: 0.5em;
}