.wrapper {
  height: 100px;
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftBlock {
  display: flex;
  flex-direction: column;
}

.rightBlock {

}

.data {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dollar {
  color: #666;
  // align-self: end;
}